import axios from "axios";
import { getToken } from '@/utils/auth'; // 假设这是获取 token 的方法

const baseURL = process.env.VUE_APP_API_BASE_URL;




const service=axios.create({
    baseURL,   
    
    /*
    headers:{
        Authorization:token
    },  
    */

    timeout:3000
})

service.interceptors.request.use(config=>{

    config.headers['Authorization'] = getToken();

    return config;
},err=>{

    //return Promise.reject(err)
    return Promise.reject(handleError(err));

})

service.interceptors.response.use(res=>{
    return res;
},err=>{
    return Promise.reject(handleError(err));

   // return Promise.reject(err)
})



function handleError(error) {
    // 根据需要，可以在这里添加对错误的详细处理逻辑
    console.error('An error occurred:', error);
    return error;
}

export default service;