import { getRuleRoutes } from '@/utils/api'


export function openEditDialog1(options){

  console.log(options)

  /*
  
    this.requestdata={
person_id:options.person_id,
person_status:options.person_status,
person_title:options.person_title,
person_tel:options.person_tel,
    }
   */



    

  //showEditDialog.value=true;
    
    
}



export  async function fetchDynamicRoutes() {
  const response = await getRuleRoutes({}); 

  console.log('response.data',response.data)
  return response.data;


}





export  function convertRoutesToVueFormat(routes) {


  return routes.map(route => {
  
    let convertedRoute = {
      path: `/${route.rule_name}`,
      name:route.rule_name,
      meta:{title:route.rule_title},
    component:  import(`../views/${route.component}.vue`) 
    };
  
   if (route.children && Array.isArray(route.children)) {
    convertedRoute.children = convertRoutesToVueFormat(route.children);
  }
  
  
  return convertedRoute;
  
  
  });
  
  
  }


