// store/index.js
import { createStore } from 'vuex';
import { fetchDynamicRoutes, convertRoutesToVueFormat } from '@/utils/utils';

export default createStore({
  state: {
    dynamicRoutes: [],
  },
  mutations: {
    setDynamicRoutes(state, routes) {
      state.dynamicRoutes = routes;
    },
  },
  actions: {
    async fetchAndSetDynamicRoutes({ commit }) {
      const dynamicRoutes = await fetchDynamicRoutes();
      commit('setDynamicRoutes', convertRoutesToVueFormat(dynamicRoutes));
    },
  },
  getters: {
    getDynamicRoutes: state => state.dynamicRoutes,
  },
});


