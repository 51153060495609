import { createApp } from 'vue'
import 'element-plus/dist/index.css'

import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
//import locale from 'element-plus/lib/locale/lang/zh-cn'
import 'video.js/dist/video-js.css';

import zhCn from 'element-plus/es/locale/lang/zh-cn'; // 引入中文语言包


import App from './App.vue'
import router from './router'
import store from './store'

const app = createApp(App)




app.use(store)
app.use(router)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }
  app.use(ElementPlus,{ 
    size: 'mini',
    locale: zhCn, // 设置语言为中文

  })
  

app.mount('#app')

//createApp(App).use(store).use(router).mount('#app')
