import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import { getRuleRoutes } from '@/utils/api'

import { useRouter } from 'vue-router';

let code=0;


const routeArray = [



  {
    path: '/',
    name: 'home',
    meta:{title:'控制台'},
     
    component:   () => import('../views/HomeView.vue'),
    children:[

      /*
      {
        path: '/index',
        name: 'index',
        meta:{title:'首页'},
         
        component: import('../views/IndexView.vue'),
        children:[
    
        ]
    
      },

      
     */

    
    ]

  },

  





  {
    path: '/login',
    name: 'login',
    meta:{title:'登录'},
    component: () => import('../views/LoginView.vue')
  },



  {
    path: '/h5',
    name: 'h5',
    meta:{title:'大屏'},
    component: () => import('../views/H5View.vue')
  },

  
  /*
  {
    path: '/aaa',
    name: 'aaa',
    meta:{title:'主页'},
    component: () => import('../views/IndexView.vue')
  },
  
*/


];





  // 假设这是一个从后端获取路由信息的函数

async function fetchRoutesFromBackend() {
  const response = await getRuleRoutes({}); 
  return response;


}


/*
function convertRoutesToVueFormat(routes) {
  return routes.map(route => ({
   // path: '/auth/group',
   // name: '/auth/group',
   // component: () => import('../views/auth/GroupView.vue')
    path: '/'+route.rule_name,
   name: route.rule_name,
   component: () => import(`../views/${route.component}View.vue`)
   //component: () => import('../views/IndexView.vue')
  // component: () => import('../views/auth/GroupView.vue')

  }));
}

*/

function convertRoutesToVueFormat(routes) {


  return routes.map(route => {

    console.log('path',`/${route.rule_name}`)
  //  let a='../views/auth/'+route.component+'.vue';


  
    let convertedRoute = {
      path: `/${route.rule_name}`,
      name:route.rule_name,
      meta:{title:route.rule_title},
     // component: () => import('../views/'+route.component+'.vue')
    //  component: () => import('@/views/'+route.component+'.vue')
    component: ()=>import(`../views/${route.component}.vue`) // 确保路径正确
    //component: import('../views/HomeView.vue'),

    //  component: () => import('../views/auth/GroupView.vue')
    //component: () => import(/* webpackChunkName: "[request]" */ `./path/to/[dynamicPart].vue`)
    };

   if (route.children && Array.isArray(route.children)) {
    convertedRoute.children = convertRoutesToVueFormat(route.children);
  }


  return convertedRoute;


});


}


async function initRoutes() {


const backendRoutesArray = await fetchRoutesFromBackend();


//

/**如果没用这个用户**/

console.log('backendRoutes.code',backendRoutesArray)

let backendRoutes=[];

if(backendRoutesArray.code=='-200'){
  code=-200;

 // return [];
  //const router = useRouter();
  //router.push('/login');
  //return false;
}else{

  backendRoutes=backendRoutesArray.data;
}




//

console.log('backendRoutes',backendRoutes)
const vueRoutes = convertRoutesToVueFormat(backendRoutes);


console.log('vueRoutes',vueRoutes)
console.log('routeArray',  routeArray)

routeArray[0]['children']=vueRoutes||[]

//routeArray[0]['children'].push(vueRoutes)

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
 //history:createWebHashHistory(process.env.BASE_URL),
  routes: routeArray,

})



return router;
}



//(async () => {

const router = await initRoutes(); // Call the async function and await its result

console.log('router',router)
//if(router){

router.beforeEach(async (to,from,next)=>{

  
 // let res=await axios.get();
   //let routeData=addDynamicRoutes(res.data.res);
   if(to.path==='/login')  return next();

   //console.log('token',localStorage.getItem('token'))

   //const token=sessionStorage.getItem('token');
   const token=localStorage.getItem('token');

   
 if(!token||code=='-200'){    
   // if(!token){

     return next('/login')
   }
   return next();

})



//}

//})();


/*
router.beforeEach(async (to,from,next)=>{
  let res=await axios.get();
   let routeData=addDynamicRoutes(res.data.res);

})


function addDynamicRoutes(result=[]){
  let HomeRoutes=routes.filter(v=>v,path=='/home')[0];
  HomeResult.children=[];
  result.forEach(item=>{
    HomeRoutes.children.push({
      path:item.path,
      name:item.name,
      meta:'',
      component:()=>import('../view/Home/${otem.component}/index.vue')
    })
  });
  return HomeRoutes;


}

*/

export default router
