
const tokenKey='token';

export function setToken(token){

    return localStorage.setItem(tokenKey,token);
   // return sessionStorage.setItem(tokenKey,token);

    

}

export function getToken(){

    return localStorage.getItem(tokenKey);

}

export function removeToken(token){

    return localStorage.removeItem(tokenKey);

}